<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 佣金转入
 * @Date: 2020-12-24 21:34:39
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-28 17:05:30
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/commissionIn.vue
-->
<template>
  <div class="user_content">
    <div class="comm_tip">佣金转入后消费不可提现，请谨慎操作。</div>
    <div class="comm_mai">
      <div style="display: flex;align-items: center;">
        <van-icon size="18" color="#E33F44" name="gold-coin-o" />
        <span style="margin-left: 5px;">账户余额</span></div>
      <span>{{ balanceMoney }}元</span>
    </div>
    <div class="comm_form">
      <div>账户余额</div>
      <van-field
        v-model="money"
        :label-width="20"
        center
        clearable
        label="￥"
        placeholder="请输入转入金额"
      >
        <template #button>
          <van-button size="small" @click="whole">全部</van-button>
        </template>
      </van-field>
      <div>当前佣金最多可转入￥{{ bonusMoney }}</div>
    </div>
    <div style="padding: 13px;">
      <van-button :class="show? 'btn bh':'btn bg'" @click="Okto">确认转入</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Field, Icon, Toast } from 'vant'

Vue.use(Icon).use(Field).use(Button).use(Button).use(Toast)
import {
  getBalanceMoneyData,
  overToBalance
} from '@/services/userApi'
import Utils from '@/utils/aes.js'

export default {
  data() {
    return {
      word: '',
      money: '',
      bonusMoney: '',
      balanceMoney: '',
      show: false
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      getBalanceMoneyData().then(res => {
        if (Number(res.code) === 200) {
          this.balanceMoney = res.data.balanceMoney
          this.bonusMoney = res.data.bonusMoney
          if (res.data.bonusMoney !== 0) {
            this.show = true
          }
        } else {
          Toast(res.msg)
        }
      })
    },
    whole() {
      if (this.bonusMoney === 0) {
        Toast('你的可转入额度不多')
      } else {
        this.show = true
        this.bonusMoney = this.money
      }
    },
    Okto() {
      if (!this.show) {
        Toast('你的可转入额度不多')
      } else {
        const parm = {
          iv: Math.floor(Math.random() * 9999999999999999),
          encryptedData: Utils.encrypt(JSON.stringify({
            'money': this.money,
            'word': this.word
          }), 'zxcvbnmasdfghjkl', Math.floor(Math.random() * 99999999999999))
        }
        overToBalance(parm).then(res => {
          if (Number(res.code) === 200) {
            // 此处为空
          } else {
            Toast(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .comm_tip {
    background: #FFEAEA;
    padding: 13px;
    color: #E33F44;
    text-align: left;
  }

  .comm_mai {
    margin-top: 10px;
    background: #FFFFFF;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .comm_form {
    margin-top: 10px;
    background: #FFFFFF;
    padding: 13px;

    & > div {
      color: #000000;
      font-size: 14px;
      text-align: left;
    }

    & > div:nth-child(2) {
      padding: 10px 0;
    }

    & > div:nth-child(3) {
      padding: 10px 0;
    }
  }

  .btn {
    color: #fff;
    padding: 13px;
    border-radius: 15px;
    width: 100%;
  }

  .bg {
    background: #DDDDDD;
  }

  .bh {
    background: #E33F44;
  }
}

</style>
